import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../Reducers/LoaderProvider';

function PrivacyAndPolicy() {
    const { setLoading } = useLoader();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, [setLoading]);

    return (
        <Box
            sx={{
                padding: { xs: '20px 20px', md: '40px 80px' },
                backgroundColor: '#F1F1F1',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    padding: { xs: '20px', md: '40px' },
                    borderRadius: '10px',
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                        marginBottom: '20px',
                    }}
                >
                    WelRM Privacy Policy for Partners
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                    <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
                        Effective Date: <span style={{ color: 'grey' }}>1 July 2024</span>
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
                        Last Updated Date: <span style={{ color: 'grey' }}>27 Nov 2024</span>
                    </Typography>
                </Box>
                <Typography sx={{ mb: 2 }}>
                    This Privacy Statement describes how WelRM Hospitality Services Pvt. Ltd. (C/O Satpal, opposite Sr. Sec. School Ghanauli, VPO Ghanauli, Distt. Ropar, Rupnagar, Punjab 140113) ("WelRM," "we," "us," "our") collects, uses, and shares information about (potential, existing or former) partners and other business partners. This Privacy Policy therefore applies to personal data of owners, employees, representatives, or other individuals acting on behalf of an accommodation partner, or other business partners (together or individually referred to as “Partners”, “you”, “your”) when you visit, access and interact on WelRM platforms, on our website (mobile and desktop) and mobile application (collectively, "Site") or other similar pages and means connected to the Site (such as contacting our support team via email, chat or telephone).
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>1. Information We Collect</Typography>
                <Typography sx={{ mb: 2 }}>
                    The information we collect depends on the choices made by a Business Partner, services, and features used. Depending on the specific situation, all the below information items may not be considered personal data because they may relate to a business instead of an individual.
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    We collect information that you provide to us when using the website or application such as your name, password, contact details such as business e-mail, business address, business phone number, position or title, images, and business interests (e.g., amounts, promotions, and facilities).
                </Typography>
                <Typography sx={{ mb: 2 }}>
                    By visiting our website, or any platform or system we operate for interacting with Business Partners, we may also automatically collect certain usage information, such as your IP address, geographic data, browser, and information about your device's operating system, application version, language settings, and pages that have been shown to you.
                </Typography>

                <Typography sx={{ mb: 2 }}>
                    Where required by law, we rely on the following legal bases to process information about you :
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>Necessity to Perform the Contract - we need to process your information in order to provide our
                        Business Partners the requested and related services, such as setting and updating
                        accommodation rates, setting promotions, changing accommodation details, providing portal
                        services, and in order to enter into contract and conduct ongoing business with Business Partners.</li>
                    <li>Our Legitimate Interests - we process your information for security and safety; to detect and
                        prevent fraud; to protect and defend the rights or property of others, or our own rights and
                        interests; to provide and tailor our business and marketing services; to conduct surveys; to share
                        information with affiliated group companies, and to respond to lawful requests, court orders, and
                        legal process.</li>
                    <li>Compliance with Legal Obligations - we need to process your information to comply with relevant
                        laws and regulatory requirements.</li>
                    <li>Consent for Non-Essential Cookies – we rely on your consent, where required, to place nonessential Cookies.</li>
                </ul>

                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>2. How We Use the Information We Collect</Typography>
                <Typography sx={{ mb: 2 }}>
                    We may use information collected through the website to:
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' ,}}>
                    <li>Provide you with the requested services, including creating a user account, verifying your account, and communicating with you.</li>
                    <li>Conduct business with the Business Partner including communicating about WelRM business.</li>
                    <li>Make and receive payments, including for reconciliation and invoicing purposes.</li>
                    <li>Provide you with information about our services, including notifications of availability, system updates, and online forums to answer common questions.</li>
                    <li>Send email marketing communications about relevant services.</li>
                    <li>Maintain and improve the website, understand user experience, and for internal training.</li>
                    <li>Protect the security of you and the website, including detecting and preventing fraudulent activities,
                        and send you security alerts.</li>
                    <li>Comply with applicable legal requirements including for tax, anti-money laundering, sanctions
                        screening.</li>
                </ul>

                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>3. Sharing and Disclosure of Your Information</Typography>
                <Typography sx={{ mb: 2 }}>
                    In connection with your visit to our website and use of our services, we may share your information as follows:
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>Third Party Service Providers - such as web hosting, data analysis, marketing or distribution of surveys, to facilitate the delivery of online services and,
                        if applicable, advertising tailored to your interests, and/or fraud prevention, user support, and other
                        add-on service providers we appoint to perform services on our behalf.</li>
                    <li> Other Business Partners - with whom we may jointly offer products or services, or whose products
                        or services may be offered on our Sites. If you choose to access these optional services, we may
                        on occasion share your information with those partners. </li>
                    <li>Where Required or Permitted by Law - such as to protect ourselves against liability, to respond
                        to subpoenas, judicial processes, legitimate requests, warrants or equivalent by law enforcement
                        officials or authorities, relevant tax authorities where legally permissible according to applicable
                        law, government and local government authorities in jurisdictions where a registration, notification,
                        permit, or license is required with a local governmental authority to list accommodation on sites
                        such as ours in accordance with local law, to investigate fraud or other wrongdoing or as otherwise
                        required or necessary in order to comply with applicable law, protect our legitimate interests or to
                        the purchasers in connection with any sale, assignment, or other transfer of all or a part of our
                        business or company. We may also, in compliance with applicable law, disclose your information
                        to enforce or apply the terms and conditions applicable to our services or to protect the rights,
                        property, or safety of WelRM, our users, or others. </li>
                    <li>Business Reorganization - such as part of any sale, assignment or other transfer of our business,
                        or transition of service to another provider. We will ask for your consent if required by applicable
                        law.</li>
                </ul>


                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>4.Cookies</Typography>
                <Typography sx={{ mb: 2 }}>
                    Our website uses and allows third parties to place cookies (session and persistent), pixels/tags, SDKs,
                    application program interfaces ("APIs"), and other technologies (collectively, "Cookies") on our website that
                    collect and store certain information about you. Some of these Cookies are necessary to provide, secure,
                    and maintain the website to you, such as to keep you logged in while your visit our website, while other
                    functional/analytic Cookies are used to provide you with a better user experience, such as:
                </Typography>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>To personalize the website by remembering information about your activities on the website (e.g., the
                        language you selected or your log-in details); and</li>
                    <li>Perform website analytics, such as demographic reporting to improve our website and services.
                        Your Choices
                        Please review your Internet browser settings, typically under the sections "Help" or "Internet Options," to
                        exercise choices you have for certain Cookies. If you disable or delete certain Cookies in your Internet
                        browser settings, you might not be able to access or use important functions or features of this website, and
                        you may be required to re-enter your log-in details. </li>
                    <Typography sx={{ my: 2 }}>
                        Please also visit the following third parties' websites to exercise certain opt-out choices over Cookies:
                    </Typography>
                    <li>Google Analytics - https://tools.google.com/dlpage/gaoptout?hl=en
                        You may need to make such choices on each browser and device you may use to exercise choice
                        regarding certain Cookies. Lastly, at this time the website is not configured to honor browsers' "Do Not Track"
                        signals</li>
                </ul>

                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>5. Mobile Devices</Typography>
                <Typography sx={{ mb: 2 }}>
                    We may send you push notifications as part of the services and, where legally required, request your express consent for it. You may also disable push notifications in your device settings.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>6. How You Can Access or Change Your Information</Typography>
                <Typography sx={{ mb: 2 }}>
                    If you wish to update your information, or in certain circumstances exercise rights to access, correct,
                    delete, restrict, or object to processing of your information, as well as the right to revoke consent for
                    certain processing or to exercise the right to data portability, please contact us by sending an email to:
                    - Business Partners<a href="mailto:info@welrm.com">info@welrm.com</a>.
                    And you can perform edit action via website and peform the changes that you want.
                    To protect your privacy and security, we will verify your identity before responding to such request, and
                    your request will be answered within a reasonable timeframe. To the extent permitted by applicable law,
                    we reserve the right to charge a reasonable fee for such access. We may need to retain certain
                    information for recordkeeping purposes, to complete any transactions that you began prior to your
                    request, or for other purposes as required or permitted by applicable law.

                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>7. Updates to Privacy Statement</Typography>
                <Typography sx={{ mb: 2 }}>
                    On occasion, WelRM may revise this Privacy Statement to reflect changes in the law, our information
                    collection and use practices, the features of our website, or advances in technology. If we make revisions
                    that change the way we collect or use your information, those changes will be posted in this Privacy
                    Policy and the effective date will be noted at the beginning of this Privacy Policy. Therefore, you
                    should review this Privacy Policy periodically so that you are up to date on our most current policies
                    and practices. WelRM will also prominently post such material changes prior to implementing the change
                    and ask for your consent if required by law.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 600, mt: 3 }}>8. Contacting Us</Typography>
                <Typography>
                    If you have any questions about this Privacy Policy or our privacy practices, please contact our WelRM
                    Registration Office by email at welrmhotels@gmail.com, or by sending a registered letter to the address
                    at:
                    <br />
                    <strong>Email:</strong> <a href="mailto:welrmhotels@gmail.com">welrmhotels@gmail.com</a>
                    <br />
                    <strong>Address:</strong> WelRM Hospitality Services Pvt. Ltd., C/O Satpal, opposite Sr. Sec. School Ghanauli, VPO Ghanauli, Distt. Ropar, Rupnagar, Punjab 140113
                </Typography>

                {/* Back Button */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="contained"
                        sx={{
                            bgcolor: '#E0E0E0',
                            color: '#000000',
                            textTransform: 'none',
                            '&:hover': {
                                bgcolor: '#D0D0D0',
                            },
                            px: 4,
                            py: 1,
                            borderRadius: '8px',
                        }}
                    >
                        Back
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default PrivacyAndPolicy;