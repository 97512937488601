const BasePath = 'https://d28em8ghn6cnbn.cloudfront.net/Home';
const IMAGES = {
    LOGO: `${BasePath}/logo.png`,
    LO: `${BasePath}/lo.png`,
    BGIMAGE: `${BasePath}/bg.png`,
    CARD1: `${BasePath}/card1.png`,
    CARD2: `${BasePath}/invoice-bill-receipt 1.png`,
    CARD3: `${BasePath}/rocket-launching.png`,
    HEROCARDIMAGE1: `${BasePath}/HeroCardImg1.png`,
    HEROCARDIMAGE2: `${BasePath}/HeroCardImg2.png`,
    HEROCARDIMAGE3: `${BasePath}/HeroCardImg3.png`,
    HEROCARDIMAGE4: `${BasePath}/businessman.png`,
    ABOUTIMAGE: `${BasePath}/aboutImg1.png`,
    GROUPIMAGE: `${BasePath}/groupimg.png`,
    ELL: `${BasePath}/Ell.png`,
    FRAME1: `${BasePath}/frame1.png`,
    FRAME2: `${BasePath}/frame2.png`,
    FRAME3: `${BasePath}/frame3.png`,
    SEC1: `${BasePath}/sec1.png`,
    SEC2: `${BasePath}/sec2.png`,
    SEC3: `${BasePath}/sec3.png`,
    HOMEBG: `${BasePath}/homebg.png`,
    BASICBG: `${BasePath}/basicbg.png`,
    QRCODE: `${BasePath}/qr-code.png`,
    PICTURE: `${BasePath}/picture.png`,
    SIDE1: `${BasePath}/side.png`,
    SIDE2: `${BasePath}/side2.png`,
    SIDE3: `${BasePath}/side3.png`,
    SIDE4: `${BasePath}/side4.png`,
    WELRM: `${BasePath}/WELRM.png`,
    AMENITIES: `${BasePath}/anities.png`,
    NOTIFICATION: `${BasePath}/notification.png`,
    DASHLOGO: `${BasePath}/Dashlogo.png`,
    DASH1: `${BasePath}/Dash1.png`,
    DASH2: `${BasePath}/Dash2.png`,
    DELETE: `${BasePath}/delete.png`,
    EDIT: `${BasePath}/edit.png`,
};

export default IMAGES;