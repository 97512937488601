// export const firebaseConfig = {
//     production: false,
//     firebase: {
//         // apiKey: "AIzaSyAwTF2bTJplUJh1tQPo0r8vPhnVMxrw_SU",
//         // authDomain: "welcomeroomweb.firebaseapp.com",
//         // projectId: "welcomeroomweb",
//         // storageBucket: "welcomeroomweb.appspot.com",
//         // messagingSenderId: "643539879956",
//         // appId: "1:643539879956:web:b657d1eff77c8e726cff01",
//         // measurementId: "G-VRXE7X3CMD"
//         apiKey: "AIzaSyDea8w0FcZwdgj41ST94c5nHVMYlF3vP-U",
//         authDomain: "welrm-a7e11.firebaseapp.com",
//         projectId: "welrm-a7e11",
//         storageBucket: "welrm-a7e11.firebasestorage.app",
//         messagingSenderId: "1083086249479",
//         appId: "1:1083086249479:web:de7d839ffc60014cf694a2",
//         measurementId: "G-S1XYLN7797"
//     }
// }


// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDea8w0FcZwdgj41ST94c5nHVMYlF3vP-U",
    authDomain: "welrm-a7e11.firebaseapp.com",
    projectId: "welrm-a7e11",
    storageBucket: "welrm-a7e11.firebasestorage.app",
    messagingSenderId: "1083086249479",
    appId: "1:1083086249479:web:de7d839ffc60014cf694a2",
    measurementId: "G-S1XYLN7797"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const facebookProvider = new FacebookAuthProvider();

export { auth, facebookProvider };

