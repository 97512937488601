import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthComponent from "./AuthComponent";
import toast from "react-hot-toast";
import { apiUrl } from "../../Shared/shared";

const YourNewPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Form validation
  const validate = () => {
    let validationErrors = {};
    if (!formData.password) {
      validationErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(formData.password)) {
      validationErrors.password =
        "Password must include at least one letter, one number, and one special character";
    }

    if (!formData.confirmPassword) {
      validationErrors.confirmPassword = "Please confirm your password";
    } else if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const token = localStorage.getItem("token");
      
      const userNumber = localStorage.getItem("userNumber");

      // const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Authorization token is missing!");
        return;
      }

      const config = {
        headers: { "oauth-token": token },
      };

      const response = await axios.put(
         `${apiUrl}/user/reset-password`,
        // "/api/user/reset-password",
        {
          newPassword: formData.password,
          confirmPassword: formData.confirmPassword,
          userNumber: userNumber
        },
        config
      );

      if (response.data.success) {
        toast.success("Password reset successfully!");
        navigate("/login");
      } else {
        toast.error(response.data.message || "Password reset failed.");
      }
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred.");
    }
  };

  return (
    <AuthComponent title={" Set Your New Password"} description={"Create a new password for your WELRM account. Ensure it is secure and memorable."}>
      <form >
        {/* Password Field */}
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Confirm Password Field */}
        <TextField
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
        onClick={handleSubmit}
          sx={{
            color: "white",
            textAlign: "center",
            bgcolor: "#C42A25",
            borderRadius: "10px",
            textAlign: "center",
            padding: "10px 50px",
            width:'100%',
            marginTop:'10px',
          }}>
          Set Password
        </Button>
      </form>
    </AuthComponent>
  );
};

export default YourNewPassword;
