import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  Grid,
  Chip,
  IconButton, Modal
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from "@mui/icons-material/Refresh";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import basicbg from "../../Assests/basicbg.png"; // Ensure this path is correct
import location from "../../Assests/location.png";
import qrcode from "../../Assests/qr-code.png";
import picture from "../../Assests/picture.png";
import SideImg from "../../Assests/side.png";
import toast from "react-hot-toast";
import { HotelData } from "../../API/Hotel";
import { useDispatch, useSelector } from "react-redux";
import BasicInformation from "./BasicInformationNav";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { apiUrl } from "../../Shared/shared";
import MapComponent from "../Map";
import QrScanner from "qr-scanner";
import { AuthDataStore } from "../../API/auth";
import { useLoader } from "../../Reducers/LoaderProvider";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
// import qr from "../../Assests/qr-code.png";
import IMAGES from "../../theme/ImagePath";

const propertyTypes = ["Choose your property type", "Hotel", "Guest House", "Resort", "Motel"];
const hotelRates = ["3 star", "1 star", "2 star", "4 star", "5 star"];

// Validation schema using Yup



const containerStyle = {
  width: "100%",
  height: "80%",
};

const center = {
  lat: 28.6139, // Default location (New Delhi)
  lng: 77.209,
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/* */

const BasicInfo = ({ setFrom }) => {
  const navigate = useNavigate()
  const [update, setUpdate] = useState(false)
  const [profile, setProfile] = useState()
  const schema = yup.object().shape({
    hotelName: yup.string().required("Hotel Name is required"),
    propertyType: yup.string().required("Property Type is required")
      .notOneOf(["Choose your property type"], "Please select a valid property type"),
    hotelRates: yup.string().required("Hote Rate is required"),

    stdCode: yup
      .string()
      .required("STD Code is required")
      .matches(/^\d{2,4}$/, "STD Code must be between 2 to 4 digits"),
    landlineNumber: yup
      .string()
      .required("Landline Number is required")
      .matches(/^\d{7}$/, "Landline Number must be exactly 7 digits"),

    qrCode: yup
      .mixed()
      // .required("QR Code is required")
      .test("qrImage", "QR Code is required", (value) => {
        return qrImage && qrImage.trim() !== ""; // Check that qrCodeValue is not empty
      }),
    landmark: yup
      .array()
      .min(1, 'At least one tag is required')
      .test("tags", "At least one Landmark is required", (value) => {
        return tags && tags.length > 0; // Check that qrCodeValue is not empty
      }),
    selectedLocation: yup
      .object()
      .nullable()
      .test('address', 'Address cannot be empty', (value) => {
        // Ensure that the address is not null or empty when the selectedLocation is provided
        return address && address.trim() !== '';
      }),
    images: yup.array()
      .test("images", "At least one Image is required", (value) => {
        return images && images.length > 0; // Check that qrCodeValue is not empty
      }),

  });
  const { setLoading } = useLoader();
  const usermobile = useSelector((state) => state.SaveNumber.mobile);
  const hotelData = useSelector((state) => state.hotelData)
  const [showFloatingBar, setShowFloatingBar] = useState(true);
  //const [isValid, setIsValid] = useState(true);
  const [images, setImages] = useState([]);
  const [uploadeimages, setUploadeimages] = useState([]);
  const [searchBox, setSearchBox] = useState(null)
  const [tags, setTags] = useState([]);  // To store added tags
  const [inputValue, setInputValue] = useState('');  // To track the current input
  const [deleteimages, setDeleteimages] = useState([]);
  const [qrImage, setQrImage] = useState(null);
  const [updateQrImage, updateSetQrImage] = useState(null);

  const [qrCodeValue, setQrCodeValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [address, setAddress] = useState();
  const [uploadedImageURLs, setUploadedImageURLs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [scanner, setScanner] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCJ6NqGpAz3uhAC9XB7MaNrkQmS0EV1Mzo", // Replace with your API key
    libraries: ['places'],
  });
  const onLoad = useCallback((ref) => setSearchBox(ref), [])
  const maxImages = 10;
  const dispatch = useDispatch()
  // React Hook Form setup
  
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
    setValue, clearErrors, reset, trigger,
  }  = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  // useEffect(() => {
  //   // Trigger validation on mount and when form data changes
  //   trigger();
  // }, [trigger]);

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces()
      if (places && places.length > 0) {
        const place = places[0]
        const newLocation = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        setSelectedLocation(newLocation)
        setAddress(place.formatted_address)
      }
    }
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedImages = reorder(images, result.source.index, result.destination.index);
    setImages(reorderedImages);
  };
  // Handle image uploads
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prevImages) => [...prevImages, reader.result]);
        setUploadeimages(prevState => [...prevState, reader.result]);
        clearErrors('images')
      };
      reader.readAsDataURL(file);
      // handleImageUpload()
    }
  };
  // Remove selected image
  const handleRemoveImage = async (img, index) => {
    if (img && img?.url?.startsWith('https:')) {
      console.log("JS");
      setDeleteimages([...deleteimages, img?.id]);
      setImages(images.filter((_, i) => i !== index));
    }
    else {
      setImages(images.filter((_, i) => i !== index));
      setUploadeimages(uploadeimages.filter((_, i) => i !== index));
      console.log('Image does not start with "https:" and will not be removed.');
    }

  };
  const handleAddTag = (event) => {
    if (event.key === 'Enter' && inputValue.trim() && inputValue.length >= 2) {
      setTags([...tags, inputValue]);
      setInputValue('');  // Clear the input field
      event.preventDefault();
      clearErrors('landmark')
    }
  };

  // Function to handle removing a tag
  const handleDeleteTag = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      console.log('qrrr', reader)

      reader.onloadend = () => {
        setQrImage(reader.result);
        updateSetQrImage(reader.result)
      };
      reader.readAsDataURL(file);
      // handleImageUpload()
    }
  };
  const handleRemove = () => {
    setQrImage();
    setQrCodeValue('');
    setValue("qrCode", null);
  };
  const getAddressFromCoordinates = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder()
    const location = { lat, lng }

    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setAddress(results[0].formatted_address)
      } else {
        console.error('Geocoder failed due to: ', status)
      }
    })
  }
  const handleSelectLocation = (event) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setSelectedLocation({ lat, lng })
    getAddressFromCoordinates(lat, lng)
  }
  const openMapModal = () => {
    setModalOpen(true);
  };

  const closeMapModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      // Get the Y offset of the element that determines where the bar should disappear
      const scrollThreshold = document.getElementById('thresholdSection').offsetTop;

      // Hide the bar if scrolled past the threshold
      setShowFloatingBar(window.scrollY + window.innerHeight < scrollThreshold);
    };
    localStorage.setItem("registrationStatus", false)
    localStorage.setItem("registrationstate", 1)
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    fetchprofile()
  }, [])
  const fetchprofile = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token not found. Please log in again.");
      return;
    }

    // Set the request headers with the OAuth token
    const config = {
      headers: {
        "oauth-token": token, // Send the token with the oauth-token key in headers
        "Content-Type": "application/json", // Ensure correct content-type header for JSON data
      },
    };
    const user = await axios.get(`${apiUrl}/owner/profile`, config);
    if (user?.data?.data?.user?.hotel) {
      setProfile(user?.data?.data)
      getMainData(user?.data?.data?.user?.hotel);
    }
  }
  async function getMainData(data) {
    setUpdate(true)
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;
    const hotelid = user?.hotel?.id ? user?.hotel?.id : data?.id;
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token not found. Please log in again.");
      return;
    }

    const config = {
      headers: {
        "oauth-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${apiUrl}/customer/hotel/${hotelid}?userId=${userId}`, config); // Replace 'API_ENDPOINT' with actual URL

    if (response.data.success) {
      const hotelData = response?.data?.data?.rows[0];
      const avgRating = hotelData?.avgRating.split(""); // e.g., 3, meaning "3 star"
      setLoading(true)
      // Reset form values with fetched data
      reset({
        hotelName: hotelData?.hotelName || '',
        propertyType: hotelData?.propertyType || propertyTypes[0],
        hotelRates: avgRating ? avgRating[0] + " star" : hotelRates[0],
        // email: hotelData?.user?.email || '',
        stdCode: hotelData?.isdCode || '',
        landlineNumber: hotelData?.telephone || '',
      });
      let landmarks = [];
      // Set the tags from the landmark data
      try {
        landmarks = hotelData?.landmark ? JSON.parse(hotelData?.landmark) : [];
        // Ensure it's an array after parsing
        if (!Array.isArray(landmarks)) {
          landmarks = [];
        }
      } catch (error) {
        // Handle the error gracefully if the landmark is not a valid JSON string
        console.error("Error parsing landmark field:", error);
        landmarks = [];
      }

      // Set tags
      setTags(landmarks);

      // Set the selected location and address
      setSelectedLocation({ lat: hotelData?.lat, lng: hotelData?.log });
      setAddress(hotelData?.address || '');
      //await setQrCodeValue(hotelData?.qrCode || '');
      // await setQrImage(hotelData?.qrCode ? IMAGES.QRCODE : '')
      await setQrImage(hotelData?.qrCode || '')
      const coverImage = hotelData?.images.find(image => image.type === 'cover');
      const otherImages = hotelData?.images.filter(image => image.type !== 'cover');

      // Reassemble the array with the cover image at the start, followed by the other images
      const updatedImages = coverImage ? [coverImage, ...otherImages] : otherImages;

      setImages(updatedImages);
      await clearAllErrors()
      // setImages(
      //     hotelData.data?.data?.rows[0]?.map(hotel => 
      //       hotel?.hotelRooms?.map(room => room?.imageUrls).flat()
      //     ).flat() || []
      //   );
      // await trigger();
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const clearAllErrors = async () => {
    clearErrors('images');
    clearErrors('landmark');
    clearErrors('qrCode')
    clearErrors("hotelName");
    clearErrors("propertyType");
    clearErrors("hotelRates");
    clearErrors("stdCode");
    clearErrors("landlineNumber");
    // clearErrors("email");
    clearErrors('selectedLocation');

    // After clearing errors, you might want to trigger validation again
    // await trigger(['landmark', 'qrCode', 'selectedLocation', 'images']);
  };
  useEffect(() => {
    clearAllErrors(); // Ensure this clears errors appropriately
    // Now validate again if necessary
    // if (update) {
    trigger();

    // }
  }, [tags, address, images, qrImage]); // Depend on whatever state you're concerned with
  useEffect(() => {

    if (qrImage) { // or any condition to check 
      clearErrors('qrCode');
      // trigger();
    }
  }, [qrImage]);

  useEffect(() => {
    if (isValid) {
      trigger(); // Optionally, re-trigger validation here as needed
    }
  }, [isValid]);
  
  
  // Handle form submission
  const onSubmit = async (data) => {
    // Ensure that images is properly defined if you intend to send it
    // const images = data.images || []; // Adjust this depending on your form input for images
    console.log("ooo", data)
    // Prepare the payload for submission
    const payload = {
      hotelName: data.hotelName,
      address: address,
      propertyType: data.propertyType,
      isdCode: data.stdCode,
      telephone: data.landlineNumber,
      images: images?.map((item, index) => {
        if (!item.hasOwnProperty('oldObj')) {
          // const isCoverImage = item === images[0]
          return {
            base64Img: item, // Assuming `completeData` holds the base64 string
            size: 11586, // Assuming size is available
            originalName: "test.png", // Assuming the original name is available
            type: index == 0 ? 'cover' : 'post' // First image as cover, the rest as post
          };
        }
        return null; // Return null for old objects if needed
      }).filter(Boolean), // Attach images correctly
      lat: selectedLocation?.lat,
      log: selectedLocation?.lng,
      mobile: usermobile,
      landmark: tags,
      qrCode: [{
        base64Img: qrImage, // Assuming `completeData` holds the base64 string
        size: 11586, // Assuming size is available
        originalName: "test.png", // Assuming the original name is available

      }],
      avgRating: data?.hotelRates.split(' ')[0]
    };
    try {
      setLoading(true);
      // Retrieve token from localStorage
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Authentication token not found. Please log in again.");
        return;
      }

      // Set the request headers with the OAuth token
      const config = {
        headers: {
          "oauth-token": token, // Send the token with the oauth-token key in headers
          "Content-Type": "application/json", // Ensure correct content-type header for JSON data
        },
      };

      // Send the form data to the API
      const response = await axios.post(`${apiUrl}/hotel/create`, payload, config); // Make sure 'apiUrl' is set to the correct API URL
      // Optional: Uncomment the line below if you need to use a PUT request instead of POST
      // const response = await axios.put('https://api.welrm.com/api/hotel/', payload, config); 

      // Retrieve user data from localStorage
      const useridvalue = await JSON.parse(localStorage.getItem("user"));

      // Get userId from retrieved user data
      const userId = useridvalue?.id;

      // Stop loading state once the request is complete
      setLoading(false);

      // Log response data for debugging

      // Create userdata object with added userId field
      const userdata = {
        ...response.data.data, // Spread the response data into userdata
        id: userId             // Add id property to the userdata
      };
      localStorage.setItem("user", JSON.stringify(userdata));
      // Log userdata for verification
      dispatch(HotelData(response.data))
      // Log the API response
      const user = await axios.get(`${apiUrl}/owner/profile`, config);
      dispatch(AuthDataStore(user.data.data))
      // Show success toast notification
      toast.success("Hotel information submitted successfully!");

      // Navigate to the next form step if successful
      setFrom(2);
    } catch (error) {
      setLoading(false);
      // Log error details for debugging
      console.error("Error:", error.response ? error.response.data : error.message);

      // Show error toast notification
      // toast.error("");
    }
  };
  const onUpdate = async (data) => {
    console.log("data", data)
    // if (qrCodeValue == "") {
    //   toast.error("please add valid qr")
    //   return;
    // }
    // Ensure that images is properly defined if you intend to send it
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;
    console.log("user", user)
    console.log("profile", profile)
    const hotelid = user?.hotel?.id ? user?.hotel?.id : profile?.hotel?.id;
    // const images = data.images || []; // Adjust this depending on your form input for images
    // Prepare the payload for submission
    console.log("dtaaa", data)
    const payload = {
      hotelName: data?.hotelName,
      address: address,
      propertyType: data?.propertyType,
      // email: data?.email,
      // email: data.email && data.email.trim() !== "" ? data.email : null,
      isdCode: data?.stdCode,
      avgRating: data?.hotelRates.split(' ')[0],
      telephone: data?.landlineNumber,
      images: uploadeimages?.map((item, index) => {
        if (!item.hasOwnProperty('oldObj')) {
          const isCoverImage = item === images[0]
          return {
            base64Img: item, // Assuming `completeData` holds the base64 string
            size: 11586, // Assuming size is available
            originalName: "test.png", // Assuming the original name is available
            type: isCoverImage ? 'cover' : 'post' // First image as cover, the rest as post
          };
        }
        return null; // Return null for old objects if needed
      }).filter(Boolean), // Filter out any null values from old images
      lat: selectedLocation?.lat,
      log: selectedLocation?.lng,
      mobile: usermobile,
      landmark: tags,
      // qrCode: qrCodeValue
      // qrCode:data?.qrCode?.name
    };
    if (updateQrImage) {
      payload.qrCode = [{
        base64Img: updateQrImage, // Assuming `completeData` holds the base64 string
        size: 11586, // Assuming size is available
        originalName: "test.png", // Assuming the original name is available
      }];
    }
    try {
      setLoading(true);
      // Retrieve token from localStorage
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Authentication token not found. Please log in again.");
        return;
      }

      // Set the request headers with the OAuth token
      const config = {
        headers: {
          "oauth-token": token, // Send the token with the oauth-token key in headers
          "Content-Type": "application/json", // Ensure correct content-type header for JSON data
        },
      };
      // Send the form data to the API
      const response = await axios.put(`${apiUrl}/hotel/mupdate`, payload, config); // Replace 'API_ENDPOINT' with actual URL
      // const response = await axios.put('https://api.welrm.com/api/hotel/', payload, config); // Replace 'API_ENDPOINT' with actual URL
      if (deleteimages && deleteimages.length > 0) {
        uploadImages(config);
      }
      // HotelData()
      console.log("qwerty", images)
      if (uploadeimages?.length == 0) {
        const response = await axios.put(`${apiUrl}/hotel/img/${hotelid}/${images[0]?.id}`, config);
      }
      setUploadeimages([])
      dispatch(HotelData(response.data))
      // Log the API response
      getMainData();
      setLoading(false);
      const user = await axios.get(`${apiUrl}/owner/profile`, config);
      dispatch(AuthDataStore(user.data.data))
      // Show success toast notification
      toast.success("Hotel information submitted successfully!");
      
      setFrom(2);
      // Navigate to the next form step if successful
      // setFrom(6);
    } catch (error) {
      // Log error details for debugging
      console.error("Error:", error.response ? error.response.data : error.message);
      setLoading(false);
      // Show error toast notification
      // toast.error("");
    }
  };
  const uploadImages = async (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;
    const hotelid = user?.hotel?.id;
    const imglength = images.length - uploadeimages.length
    const uploadPromises = deleteimages.map(async (item, index) => {

      try {
        const imageUpload = await axios.delete(
          `${apiUrl}/hotel/img/${hotelid}/${item}`,
          config
        );
        return imageUpload.data; // Optionally, return the result if needed
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    });

    // Wait for all images to be uploaded
    await Promise.all(uploadPromises);
  };
  const handleImageUpload = async () => {
    const formData = new FormData();

    images.forEach((image, index) => {
      formData.append('image', image); // Make sure to append the correct file
    });

    try {
      const response = await axios.post(`${apiUrl}/hotel/img`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const url = `http://localhost:5001/img/${response.data.file.filename}`;
      setUploadedImageURLs(prev => [...prev, url]);

      toast.success("Image uploaded successfully!");
    } catch (error) {
      console.error("Upload failed:", error.response ? error.response.data : error.message);
      toast.error("Image upload failed!");
    }
  };
  const handleBack = () => {
    navigate(-1)
  }
  return (
    <div>
      <Box
      >
        <Box sx={{ borderBottom: "1px solid lightgrey", padding: { xs: "20px 20px 10px 0px ", }, }}>
          <Typography sx={{ fontSize: { xs: "18px", sm: "20px", md: "22px" }, textAlign: "start", fontWeight: 600, color: "#000" }}>
          Ready to Welcome Guests?  <span style={{ color: "#C42A25", fontSize: "20px" }}>(Just add hotel!)</span>
          </Typography>
        </Box>
        <Box >

        </Box>
        <Box
        >
          {/* <Box
            >
            
          </Box> */}

          {/* Basic Information Section */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ px: 0, pt: 3 }}>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1, textAlign: "start" }}>
                    Hotel Name
                  </Typography>
                  <Controller
                    name="hotelName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter hotel  name"
                        error={!!errors.hotelName}
                        helperText={errors.hotelName?.message}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e); // Update the value
                          clearErrors("hotelName"); // Clear all errors on change
                        }}
                      />
                    )}
                  />
                </Grid>


                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1, textAlign: "start" }}>
                    Select Property Type
                  </Typography>
                  <Controller
                    name="propertyType"
                    control={control}
                    defaultValue={propertyTypes[0]}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        error={!!errors.propertyType}
                        helperText={errors.propertyType?.message}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e); // Update the value
                          clearErrors("propertyType"); // Clear all errors on change
                        }}
                        sx={{
                          textAlign: "start",
                          "& .MuiSelect-select": {
                            color: field.value === propertyTypes[0] ? "#9e9e9e" : "#000",
                          },
                        }}
                      >
                        {propertyTypes.map((type, index) => (
                          <MenuItem key={type} value={type} sx={{ color: index === 0 ? "#9e9e9e" : "#000", }}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1, textAlign: "start" }}>
                    Property Rating
                  </Typography>
                  <Controller
                    name="hotelRates"
                    control={control}
                    defaultValue={hotelRates[0]}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        select
                        variant="outlined"
                        error={!!errors.hotelRates}
                        helperText={errors.hotelRates?.message}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e); // Update the value
                          clearErrors("hotelRates"); // Clear all errors on change
                        }}
                        sx={{
                          textAlign: "start",
                          "& .MuiSelect-select": {
                            color: field.value === hotelRates[0] ? "#9e9e9e" : "#000",
                          },
                        }}
                      >
                        {hotelRates.map((type, index) => (
                          <MenuItem key={type} value={type} sx={{ color: index === 0 ? "#9e9e9e" : "#000", }}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                {/*  */}

                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1, textAlign: "start" }}>
                    STD Code *
                  </Typography>
                  <Controller
                    name="stdCode"

                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type 2 to 4 digits code"
                        error={!!errors.stdCode}
                        inputProps={{ maxLength: 4 }}
                        helperText={errors.stdCode?.message}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e); // Update the value
                          clearErrors("stdCode"); // Clear all errors on change
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1, textAlign: "start" }}>
                    Landline Number *
                  </Typography>
                  <Controller
                    name="landlineNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type landline number"
                        error={!!errors.landlineNumber}
                        inputProps={{ maxLength: 7 }}
                        helperText={errors.landlineNumber?.message}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e); // Update the value
                          clearErrors("landlineNumber"); // Clear all errors on change
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} container spacing={2}>
                  {/* <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Typography sx={{ fontSize: "16px", fontWeight: 500, mb: 1 }}>
                      STD Code *
                    </Typography>
                    <Controller
                      name="stdCode"

                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Type 2 to 4 digits code"
                          error={!!errors.stdCode}
                          inputProps={{ maxLength: 4 }}
                          helperText={errors.stdCode?.message}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e); // Update the value
                            clearErrors("stdCode"); // Clear all errors on change
                          }}
                        />
                      )}
                    />
                  </Grid> */}




                </Grid>
              </Grid>
            </Box>

            {/* STD Code, Landline Number, and Hotel Location */}
            <Box sx={{ p: 0 }}>
              <Grid container spacing={0}>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography  sx={{ fontSize: '16px', fontWeight: 400, mb: 1, color: '#000', textAlign: "start" }}>
                   Landmark
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid #D0D5DD',
                      borderRadius: '8px',
                      padding: '8px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '8px',
                    }}
                  >
                    <TextField   label="Type here"
                      fullWidth
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyPress={(e) => handleAddTag(e)} // Add tag on 'Enter' key press
                      variant="outlined"
                     className="cs"
                      size="small"
                      sx={{ flexGrow: 1, minWidth: '150px' }}
                    />
                    {/* Render existing tags */}
                    <div style={{ maxHeight: '70px', overflowY: 'auto', gap: '8px', padding: '8px' }}>
                      {tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          onDelete={() => handleDeleteTag(tag)}
                          sx={{ backgroundColor: 'lightgrey', borderRadius: '8px', padding: '4px' }}
                          deleteIcon={<CloseIcon fontSize="small" />}
                        />
                      ))}
                    </div>

                  </Box>

                  {/* Display validation errors for tags */}
                  {errors.landmark && (
                    <Typography color="error" variant="body2" >
                      {errors.landmark.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="email"
                        variant="outlined"
                        placeholder="Enter email address"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        {...field}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          field.onChange(e); // Update the value
                          clearErrors("email"); // Clear all errors on change
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>

                  <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px" }, fontWeight: 500, mt: 2, mb: 1, textAlign: "start", }}>
                    Upload Payment QR Code Image (Only 1)
                  </Typography>

                  <Box
                    sx={{
                      display: { md: "flex" }, // Apply flex only on medium screens and above
                      alignItems: "stretch", // Ensures both child boxes have the same height
                      flexWrap: "wrap", // Wraps on smaller screens
                      gap: 3, // Adds spacing between items
                      paddingX: { xs: 0, sm: 0, md: 0, lg: 0 },
                      justifyContent: "space-between", // Distributes items evenly
                    }}
                  >
                    {/* QR Code Upload Section */}
                    <Box
                      sx={{
                        flex: 1,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column", // Ensures the content inside is aligned properly
                        justifyContent: "center", // Centers content vertically
                      }}
                    >
                      <Controller
                        name="qrCode"
                        control={control}
                        render={({ field }) => (
                          <>
                            {!qrImage ? (
                              <Button
                                component="label"
                                fullWidth
                                startIcon={<img src={IMAGES.QRCODE} alt="qrcode" style={{ width: "20px", height: "20px" }} />}
                                sx={{
                                  p: 1,
                                  backgroundColor: "#F1F5F9",
                                  color: "#000",
                                  border: "1px solid #CBD5E1",
                                }}
                              >
                                Upload QR Code
                                <input
                                  type="file"
                                  hidden
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      if (file.size > 200 * 1024) { // 200KB limit
                                        toast.error("File size must be less than 200KB!");
                                        return; // Stop execution if file is too large
                                      }
                                      field.onChange(file);
                                      handleUpload(e);
                                    }
                                  }}
                                  accept="image/*"
                                />
                              </Button>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  width: "140px",
                                  height: "140px",
                                  margin: "auto",
                                  borderRadius: "8px",
                                  backgroundColor: "#F1F5F9",
                                  marginBottom: "20px",
                                }}
                              >
                                <img
                                  src={qrImage}
                                  alt="QR Code Preview"
                                  style={{ width: "50%", height: "50%", objectFit: "cover" }}
                                />
                                <IconButton
                                  onClick={handleRemove}
                                  sx={{
                                    position: "absolute",
                                    top: 5,
                                    left: 5,
                                    backgroundColor: "rgba(255,255,255,0.8)",
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                    backgroundColor: "rgba(255,255,255,0.8)",
                                  }}
                                >
                                  <RefreshIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            )}
                            {errors.qrCode && (
                              <Typography color="error" variant="body2">
                                {errors.qrCode.message}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    </Box>

                    {/* Map Section */}
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column", // Aligns content inside properly
                        justifyContent: "center", // Centers the content vertically
                        minHeight: "100%", // Ensures it stretches equally
                        marginTop: { xs: 0, lg: 0, sm: 0 },
                      }}
                    >
                      <MapComponent
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        setAddress={setAddress}
                        address={address}
                        clearErrors={clearErrors}
                      />
                      {errors.selectedLocation && (
                        <Typography color="error" variant="body2" sx={{ textAlign: "start" }}>
                          {errors.selectedLocation.message}
                        </Typography>
                      )}
                    </Box>
                  </Box>


                  {/* <Box sx={{ paddingX: { xs: "0px", sm: "30px", md: "50px", lg: "60px" } }}>

  <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
    <Controller
      name="qrCode"
      control={control}
      render={({ field }) => (
        <>
          {!qrImage ? (
            <Button
              component="label"
              fullWidth
              startIcon={<img src={IMAGES.QRCODE} alt="qrcode" />}
              sx={{
                p: 1,
                backgroundColor: '#F1F5F9',
                color: '#000',
                // marginBottom: '20px',
                border: '1px solid #CBD5E1',
              }}
            >
              Upload QR Code
              <input
                type="file"
                hidden
                onChange={(e) => {
                  field.onChange(e.target.files[0]);
                  handleUpload(e);
                }}
                accept="image/*"
              />
            </Button>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                width: '140px',
                height: '140px',
                margin: 'auto',
                borderRadius: '8px',
                backgroundColor: '#F1F5F9',
                marginBottom: '20px',
              }}
            >
              <img
                src={qrImage}
                alt="QR Code Preview"
                style={{ width: '80%', height: '80%', objectFit: 'cover' }}
              />
              <IconButton
                onClick={handleRemove}
                sx={{
                  position: 'absolute',
                  top: 5,
                  left: 5,
                  backgroundColor: 'rgba(255,255,255,0.8)',
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  backgroundColor: 'rgba(255,255,255,0.8)',
                }}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
          {errors.qrCode && (
            <Typography color="error" variant="body2">
              {errors.qrCode.message}
            </Typography>
          )}
        </>
      )}
    />
  </Box>

  <Box sx={{ marginTop: 3 }}>
    <MapComponent selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} setAddress={setAddress} address={address} clearErrors={clearErrors} />
    {errors.selectedLocation && (
      <Typography color="error" variant="body2" sx={{ textAlign: 'center' }}>
        {errors.selectedLocation.message}
      </Typography>
    )}
  </Box>
</Box> */}
                </Grid>

              </Grid>
            </Box>

            {/* Hotel Image Upload Section */}
            <Box
              sx={{
                p: 2,
                marginTop: 2,
                backgroundColor: "#FFFFFF", // White background
                borderRadius: "12px", // Rounded corners
                border: "1px solid #E5E7EB", // Light border
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle box-shadow
              }}
            >
              <Typography sx={{ fontSize: "20px", fontWeight: 700, mb: 1, textAlign: "start", }}>
                Add Hotel Images <span style={{ fontSize: "16px" }}>(Max 10)</span>
              </Typography>

              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  mb: 1,
                  fontStyle: "italic",
                  color: "#4B5563",
                  textAlign: "start",
                }}
              >
                The building's exterior, parking space(s), entrance, & any available facilities
              </Typography>

              {/* Drag and Drop Image Upload Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 3,
                  border: "1px dashed #CBD5E1", // Dashed border for creative look
                  borderRadius: "12px", // Smooth edges
                  backgroundColor: "#F9FAFB", // Light background for contrast
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                              }}
                            >
                              {images?.map((image, index) => (
                                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                                  {(provided, snapshot) => (
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        position: "relative",
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                        border: "1px solid #ddd",
                                        boxShadow: snapshot.isDragging ? "0px 4px 8px rgba(0, 0, 0, 0.15)" : "none",
                                      }}
                                    >
                                      <img
                                        src={image?.url ? image?.url : image}
                                        alt={`Preview ${index}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                      {index === 0 && (
                                        <Box
                                          sx={{
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            width: "100%",
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            color: "white",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "5px 0",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            opacity: 0.7,
                                          }}
                                        >
                                          Cover
                                        </Box>
                                      )}
                                      <Button
                                        sx={{
                                          position: "absolute",
                                          top: 0,
                                          right: 0,
                                          padding: "2px",
                                          minWidth: "unset",
                                          bgcolor: "rgba(255,255,255,0.8)",
                                          fontSize: "12px",
                                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                        }}
                                        onClick={() => handleRemoveImage(image, index)}
                                      >
                                        X
                                      </Button>
                                    </Box>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      {/* Add More Images Button */}
                      {images.length < maxImages && (
                        <Button
                          component="label"
                          variant="outlined"
                          sx={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            color: "#888",
                            padding: "0px",
                            border: "none",
                            backgroundColor: "#F3F4F6",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={IMAGES.PICTURE}
                            alt="Add Icon"
                          />
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                if (file.size > 300 * 1024) { // 300KB limit
                                  toast.error("File size must be less than 300KB!");
                                  return;
                                }
                                handleImageChange(e);
                              }
                            }}
                            accept="image/*"
                          />
                        </Button>
                      )}
                    </Box>

                    {/* Display Validation Errors */}
                    {errors.images && (
                      <Typography color="error" variant="body2">
                        {errors.images.message}
                      </Typography>
                    )}
                  </Grid>

                  {/* Submit Button Section */}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      id="thresholdSection"
                      sx={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        onClick={update ? handleSubmit(onUpdate) : handleSubmit(onSubmit)}
                        type="submit"
                        variant="contained"
                        id="basicInfoSubmit"
                        style={{ display: "none" }}
                        disabled={!isValid}
                        sx={{
                          bgcolor: isValid ? "#C42A25" : "#C42A2580",
                          color: "#fff",
                          borderRadius: "8px",
                          padding: "12px 20px",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        Save and Continue
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

          </form>
          
        </Box>
      </Box>
    </div>
  );
};
export const preventEarlyValidation = (trigger, clearErrors) => {
  let validationTriggered = false;

  return async () => {
    if (!validationTriggered) {
      clearErrors(); // ✅ Clear any existing errors on the first click
      validationTriggered = true; // ✅ Ensure validation runs only when clicking next
      return true;
    }

    // ✅ Validate only when the user is moving to the next step
    const isStepValid = await trigger(undefined, { shouldFocus: true });

    return isStepValid;
  };
};

export default BasicInfo;
