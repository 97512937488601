import React, { useState, useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "@mui/material";
import { apiUrl } from "../../Shared/shared";
import toast from "react-hot-toast";

import logo from "../../Assests/logo.png";
import loginImg from "../../Assests/login.png";
import { useLocation, useNavigate } from "react-router-dom";
import AuthComponent from "./AuthComponent";

const VerifyOtpEmailPhone = ({ mobileNumber }) => {
  const [otp, setOtp] = useState(""); 
  const [timeLeft, setTimeLeft] = useState(120);
  const [error, setError] = useState(""); 
  const [resendVisible, setResendVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
    if (timeLeft === 0) {
      setResendVisible(true);
    }
  }, [timeLeft]);

  const handleChange = (newValue) => {
    setOtp(newValue);
    if (error) setError("");
  };

  const handleVerify = async () => {
    if (otp.length !== 6 || isNaN(otp)) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }

    const payload = {
      otp: Number(otp),
      countryCode: 91,
      mobile: Number(mobileNumber),
    };

    try {
      console.log("Sending OTP verification payload:", payload);
      const response = await axios.post(`${apiUrl}/user/verify-otp`, payload);
      console.log("OTP Verification Response:", response.data);

      if (response.data.success === true) {
        localStorage.setItem("token", response.data.data.accesstoken);
        toast.success("OTP Verified Successfully!");
        navigate('/basicInfo');
      } else {
        toast.error(response.data.message || "Invalid OTP!");
      }
    } catch (error) {
      toast.error("Verification failed. Please try again.");
    }
  };

  return (
    <Box>
      <MuiOtpInput value={otp} onChange={handleChange} length={6} />

      {error && <Typography sx={{ color: "red", fontSize: "14px", marginTop: "10px", textAlign: "center" }}>{error}</Typography>}

      <Box sx={{ marginTop: "15px", textAlign: "center" }}>
        <Button onClick={handleVerify} sx={{ bgcolor: "#C42A25", color: "white", padding: "10px 50px" }}>Confirm</Button>
      </Box>
    </Box>
  );
};

export default VerifyOtpEmailPhone;

