import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Button, Grid,
  InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import { apiUrl } from "../../Shared/shared";
import { useDispatch } from "react-redux";
import saveNumber from "../../API/saveNumber";
import { MuiOtpInput } from "mui-one-time-password-input";
import * as actions from "../../Actions/actions";
import BasicInfo from "../BasicInformation/BasicInfo"; // ✅ Import BasicInfo form
import RoomDetails from "../BasicInformation/RoomDetails"; // ✅ Import RoomDetails

import logo from "../../Assests/logo.png";

const Registration = () => {
  const dispatch = useDispatch();
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [showBasicInfo, setShowBasicInfo] = useState(false); // ✅ Show BasicInfo after OTP verification
  const [extraClass, setExtraClass] = useState("");


  // ✅ Validation Schema
  const schema = yup.object().shape({
    ownerName: yup.string().required("Owner Name is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .min(10, "Phone number must be exactly 10 digits")
      .max(10, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  // ✅ Send OTP function
  const sendOtp = async () => {
    setLoading(true);
    let otpSentSuccessfully = false; // Flag to track OTP success

    try {
      const response = await axios.post(`${apiUrl}/user/send-otp/owner`, {
        countryCode: 91,
        mobile: Number(phoneNumber),
        userType: "owner",
        fullName: ownerName,
        email: email,
      });

      console.log(response.data);

      if (response.data.success) {
        otpSentSuccessfully = true;
        toast.success("OTP sent successfully!");

        // Proceed normally
        dispatch(saveNumber(phoneNumber));
        dispatch(saveNumber(ownerName));
        dispatch(saveNumber(email));
        dispatch(actions.init_auth());
        localStorage.setItem("userNumber", phoneNumber);

        setOtpSent(true); // Allow user to enter OTP
      }
      else if (response.data.message === "User Already Exists") {
        toast.error("User Already Exists. Proceeding with verification...");

        // Forcefully verify OTP and show Basic Info
        setOtpVerified(true);
        setShowBasicInfo(true);
        setExtraClass("setImage");
      }
      else {
        toast.error(response.data.message);
        setOtpSent(true); // Allow user to enter OTP
      }
    } catch (error) {
      // toast.error("Failed to send OTP. Proceeding anyway...");
    }

    setLoading(false);
  };



  // ✅ OTP Verification Function
  const verifyOtp = async () => {
    if (otp.length !== 6 || isNaN(otp)) {
      setOtpError("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/user/verify-otp`, {
        otp: Number(otp),
        countryCode: 91,
        mobile: Number(phoneNumber),
      });

      if (response.data.success) {
        localStorage.setItem("token", response.data.data.accesstoken);
        toast.success("OTP Verified!");
        setOtpVerified(true);
        setShowBasicInfo(true); // ✅ Show BasicInfo after OTP verification
        setExtraClass("setImage");
      } else {
        toast.error("Invalid OTP!");
      }
    } catch (error) {
      toast.error("OTP verification failed.");
    }
  };


  // ✅ Password Submission Function
  const handlePasswordSubmit = async () => {
    if (!password || !confirmPassword) {
      toast.error("Please fill out both password fields.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      console.log(token);
      if (!token) {
        // toast.error("Authentication token not found.");
        return;
      }

      const config = {
        headers: {
          "oauth-token": token,
          "Content-Type": "application/json",
        },
      };
      const userNumber = localStorage.getItem("userNumber");

      const payload = {
        newPassword: password,
        confirmPassword: confirmPassword,
        userNumber: userNumber,
      };

      const response = await axios.put(
        `${apiUrl}/user/reset-password`,
        payload,
        config
      );
      localStorage.setItem('users', response.data.data);
      localStorage.setItem('user', JSON.stringify(response.data.data.user));

      if (response.data.success) {
        toast.success("Password set successfully!");
        setRegistrationComplete(true);
      } else {
        toast.error("Password update failed.");
      }
    } catch (error) {
      // toast.error("Error updating password.");
    }
  };

  return (
    <Box className="imageBox">
      <Box className={`imageBoxs ${extraClass}`}
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingBottom: "20px",
        }}>
        {/* ✅ Header with Logo */}
        {/* <Box sx={{ padding: "20px", backgroundColor: "#F1F1F1" }}>
        <img src={logo} alt="Logo" style={{ height: "50px" }} />
      </Box> */}

        <Box
          sx={{
            maxWidth: "85%",
            margin: "auto",
            padding: "20px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            backgroundColor: "white",
            textAlign: "center",
          }}
        >      <Box sx={{ padding: "20px", textAlign: "center" }}>
            <img src={logo} alt="Logo" style={{ height: "50px", display: "block", margin: "0 auto" }} />
          </Box>
          <Typography variant="h5" fontWeight="bold">
            Register Your Account
          </Typography>
          <Typography >
            Start Your Hosting Journey – Sign Up Now!
          </Typography>

          {/* ✅ Owner Name Field */}



          {/* grid */}
          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={6} lg={4}>


              <TextField
                fullWidth
                label="Owner Name"
                margin="normal"
                variant="outlined"
                error={!!errors.ownerName}
                helperText={errors.ownerName?.message}
                value={ownerName}
                {...register("ownerName")}
                onChange={(e) => setOwnerName(e.target.value)}
              />

              {/* ✅ Email Field */}


            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>

              {/* ✅ Email Field */}
              <TextField
                fullWidth
                label="Email"
                type="email"
                margin="normal"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message}
                value={email}
                {...register("email")}
                onChange={(e) => setEmail(e.target.value)}
              />


            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>


              <TextField
                type="text"
                label="Phone Number"
                fullWidth
                {...register("phoneNumber")}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}

                margin="normal"
                variant="outlined"
                sx={{ flex: 1 }}
                inputProps={{ maxLength: 10 }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))}
              />
            </Grid>

          </Grid>

         





          {/* otp */}

          {!otpSent && !otpVerified && (





            <Button onClick={handleSubmit(sendOtp)} sx={{
              backgroundColor: "#C42A25",
              color: "white",
              marginLeft: 2,
              padding: "15px 20px",
              // 15px top-bottom, 10px left-right
            }}>
              {loading ? "Sending..." : "Send OTP"}
            </Button>

          )}

          {/* ✅ OTP Verification */}
          {otpSent && !otpVerified && (
            <Box sx={{
              marginTop: 2,   // Explicit width
            }}><Typography variant="body1" sx={{ marginBottom: 2, color: "#333", textAlign: "center", fontWeight: 600, fontSize: "17px", }}>
                Enter your phone number to receive an OTP
              </Typography>
              <MuiOtpInput
                value={otp}
                onChange={(value) => setOtp(value)}
                length={6}
                TextFieldsProps={{
                  sx: {
                    width: "50px", // Set width of each OTP input box
                    textAlign: "center",
                    justifyContent: "content",
                  },
                }}
              />
              <Button onClick={verifyOtp} sx={{ backgroundColor: "#C42A25", color: "white", marginLeft: 2, padding: "15px 20px", marginTop: 3 }}>
                Verify
              </Button>
            </Box>
          )}

          {/* ✅ Password Fields */}
          {otpVerified && (
            <Box sx={{
              marginTop: 0, display: "flex",
              gap: 2,
            }}>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={4}>
                  <TextField fullWidth label="Password*" type="password" sx={{ marginTop: 2 }} onChange={(e) => setPassword(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <TextField fullWidth label="Confirm Password*" type="password" sx={{ marginTop: 2 }} onChange={(e) => setConfirmPassword(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <TextField fullWidth label="Security question: Enter Pet name" type="text" sx={{ marginTop: 2 }} />
                </Grid>
              </Grid>



              <Button id="passwordSubmit" style={{ display: 'none' }} onClick={handlePasswordSubmit} sx={{ backgroundColor: "#C42A25", color: "white", marginTop: 2 }}>
                Submit
              </Button>
            </Box>
          )}

          {showBasicInfo && (
            <>
             
              <Box
                sx={{

                }}
              >
                <Box sx={{ flex: 1, minWidth: { xs: "100%", sm: "48%" } }}>
                  <BasicInfo />
                </Box>
                <Box sx={{ flex: 1, minWidth: { xs: "100%", sm: "48%" } }}>
                  <RoomDetails />
                </Box>
              </Box>

              {/*  */}
              {/* ✅ Final Submit Button (Appears After OTP Verification) */}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#C42A25",
                  color: "#fff",
                  borderRadius: "8px",
                  padding: "12px 20px",
                  marginTop: "0px",
                  display: "block",
                  width: "100%", // Full-width button
                  textAlign: "center",
                }}
                onClick={() => {
                  document.getElementById("passwordSubmit").click(); // First, submit password
                  setTimeout(() => {
                    document.getElementById("basicInfoSubmit").click();
                  }, 3000); // Then, submit Basic Info
                  setTimeout(() => {
                    document.getElementById("roomDetailsSubmit").click(); // Finally, submit Room Details
                  }, 12000);
                }}
              >
                Final Submit
              </Button>

            </>
          )}

        </Box>


      </Box>
    </Box>
  );
};

export default Registration;


